import { ApolloError } from '@humans-sdk/libs/apollo/client';
import { generatePath } from '@humans-sdk/libs/react-router-dom';

import { mutationSetActiveWorkspace } from '@api/mutations';
import { workspacesAndWidgetsQuery } from '@api/queries';
import { RouteNames, RoutingStatus } from '@constants';
import { routes } from '@routes';
import {
    AppRouteFetchProps,
    AppRouteFetchReturn,
    DefaultRouteProps,
    DynamicImport,
    EnterpriseGraphQLSchema,
    WorkspaceRouteProps,
} from '@typings';

const WorkspacePage: DynamicImport = () =>
    import(
        /* webpackPrefetch: true */
        /* webpackChunkName: 'WorkspacePage' */
        './workspacePage'
    );

export default {
    module: WorkspacePage,
    fetch: ({ client, match, history }: AppRouteFetchProps<DefaultRouteProps>): AppRouteFetchReturn => {
        const requests: Promise<unknown>[] = [];
        const currentWorkspaceId = match?.params.workspaceId;

        const activeWorkspaceId = client.readQuery<Pick<EnterpriseGraphQLSchema.Query, 'me'>>({
            query: workspacesAndWidgetsQuery,
        })?.me?.workspaces.all.active.id;

        const fetchWorkspace = () =>
            client.query<Pick<EnterpriseGraphQLSchema.Query, 'me'>>({
                query: workspacesAndWidgetsQuery,
                fetchPolicy: 'network-only',
            });

        if (currentWorkspaceId && currentWorkspaceId !== activeWorkspaceId) {
            let isWrongWorkspaceId = false;

            requests.push(
                new Promise((resolve, reject) => {
                    void client
                        .mutate<EnterpriseGraphQLSchema.Mutation, EnterpriseGraphQLSchema.SetActiveWorkspaceInput>({
                            mutation: mutationSetActiveWorkspace,
                            variables: {
                                workspaceID: currentWorkspaceId,
                            },
                            fetchPolicy: 'no-cache',
                        })
                        .catch((error: ApolloError) => {
                            const { graphQLErrors } = error;

                            if (
                                graphQLErrors?.find(
                                    (e) =>
                                        e.path?.length === 1 &&
                                        e.path[0] === 'setActiveWorkspace' &&
                                        e.extensions?.error_code === 'InvalidArgument',
                                )
                            ) {
                                // catch error while setActiveWorkspace
                                // in case non-correct `workspaceId` was passed
                                isWrongWorkspaceId = true;
                            } else if (graphQLErrors?.length > 0) {
                                throw error;
                            }
                        })
                        .finally(() => {
                            void fetchWorkspace().then(({ data }) => {
                                if (isWrongWorkspaceId) {
                                    const workspaceId = data.me?.workspaces.all.active.id;

                                    if (workspaceId) {
                                        // Redirect to correct URL
                                        const params: WorkspaceRouteProps = {
                                            workspaceId,
                                        };

                                        const href = generatePath(routes[RouteNames.workspace].path, params);
                                        history.replace(href);
                                    }

                                    // Return rejected promise with redirect status
                                    reject(RoutingStatus.redirect);
                                } else {
                                    resolve(true);
                                }
                            });
                        });
                }),
            );
        } else if (!activeWorkspaceId) {
            requests.push(fetchWorkspace());
        }

        return requests;
    },
};
