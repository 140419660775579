import { ReactElement, ReactNode } from 'react';

import { WidgetSizeNames } from '@humans-sdk/enterprise/typings';

import { NameSpaces } from '@constants';
import { WidgetComponent, WidgetSize } from '@typings';

import { LibraryApproved } from './library';

export enum ContentType {
    widgets = 'widgets',
    components = 'components',
}

export interface ContentClasses {
    root?: string;
    widgets?: string;
    leftPadding: string;
}

export interface ContentProps {
    classes: ContentClasses;
    children?: ReactNode;
    type: ContentType;
    isCompactMenu: boolean;
}

export type CellWidgetParamsType = {
    size: WidgetSize;
    origin: number;
    pinned?: boolean;
};

export type CellWidgetType = {
    id: string;
    widgetId: string;
    namespace: NameSpaces;
    component: WidgetComponent;
    params: CellWidgetParamsType;
};

export type WorkspaceCellType = null | CellWidgetType;
export type WorkspaceStateType = [WorkspaceCellType, WorkspaceCellType, WorkspaceCellType, WorkspaceCellType];

export type WorkspaceGridElementType = ReactElement | undefined | null;
export type WorkspaceGridType = [WorkspaceGridElementType, WorkspaceGridElementType, WorkspaceGridElementType, WorkspaceGridElementType];

export type UpdateWorkspaceParams = {
    namespace: NameSpaces;
    cellIndex?: number;
    cells: WorkspaceStateType;
    pinnedStore?: string[];
    onUpdate: Function;
    targetSize?: WidgetSize;
    library: LibraryApproved;
    pinned?: boolean;
    enqueueSnackbar: () => void;
};

export type UpdateCellsParams = {
    cells: WorkspaceStateType;
    widget: CellWidgetType;
};

export type ExcludeSizes = {
    [P in NameSpaces]?: WidgetSize[];
};

export type VariantWidgetMountType = {
    namespace: NameSpaces;
    origin: number;
    mask: number;
    size: WidgetSize;
    cells: WorkspaceStateType;
};

export type MountGridParams = {
    library: LibraryApproved;
    namespace: NameSpaces;
    variants: WorkspaceStateType[];
    cells: WorkspaceStateType;
};

export type MountWidgetParams = {
    library: LibraryApproved;
    cells: WorkspaceStateType;
    namespace: NameSpaces;
    origin?: number;
    targetSize?: WidgetSize;
    pinned?: boolean;
};

export interface GenWidgetProps {
    Widget: WidgetComponent;
    namespace: NameSpaces;
    size: WidgetSize;
    origin: number;
    availableSizes: WidgetSizeNames[];
    handleSizeChange: HandleSizeChangeType;
    handleRemoveWidget: HandleRemoveWidgetType;
    handlePinned: HandlePinnedWidgetType;
    pinned?: boolean;
}

export type GenWidgetType = (props: GenWidgetProps) => WidgetComponent;

export interface HandleSizeChangeProps {
    targetSize: WidgetSizeNames;
    size: WidgetSize;
    origin: number;
    namespace: NameSpaces;
}

export type HandleSizeChangeType = (props: HandleSizeChangeProps) => void;
export type HandleRemoveWidgetType = (namespace: NameSpaces) => void;
export type HandlePinnedWidgetType = (namespace: NameSpaces) => void;
