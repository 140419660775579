import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { combineLatest } from 'rxjs';

import { DialogRoutes } from '@humans-sdk/constants/auth';
import { IconButton, SnackbarActionButton, SpinnerOverlay, Text, Tooltip } from '@humans-sdk/core/components';
import { ExpandMoreIcon } from '@humans-sdk/core/icons';
import { useWebsocket } from '@humans-sdk/core/kits';
import { useSnackbar } from '@humans-sdk/libs/notistack';
import { useHistory } from '@humans-sdk/libs/react-router-dom';
import { AuthStatuses, WebsocketContextType } from '@humans-sdk/typings';

import { useAuth } from '@api';
import { WS_DISCONNECTION_SNACKBAR_BUTTON_TEXT, WS_DISCONNECTION_SNACKBAR_TEXT } from '@constants';
import Content from '@containers/content';
import Header from '@containers/header';
import Library from '@containers/library';
import Sidebar from '@containers/sidebar';
import { WorkspaceProvider } from '@containers/workspace';
import { ContentType } from '@typings';
import { clsx } from '@utils';

import s from './Layout.module.scss';

interface LayoutClasses {
    content?: string;
    widgets?: string;
}

interface Props {
    classes?: LayoutClasses;
    children?: ReactNode;
    withLibrary?: boolean;
    type?: ContentType;
}

const Layout = ({ children, classes, type = ContentType.components, withLibrary = false }: Props): ReactElement | null => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCompact, setIsCompact] = useState(localStorage.getItem('layoutIsCompact') === 'true');
    const { status } = useAuth();
    const history = useHistory();
    const classNames = useMemo(() => clsx(s.root, withLibrary && s.withLibrary, isCompact && s.expanded), [isCompact, withLibrary]);
    const classNamesContentRoot = useMemo(() => clsx(s.content, classes?.content), [classes]);
    const { pathname } = history.location;
    const isNewRegistration = useMemo(
        () => pathname === DialogRoutes.RESET_MASTER_PIN || pathname === DialogRoutes.REGISTRATION,
        [pathname],
    );
    const isPendingAuth = useMemo(() => status === AuthStatuses.PENDING_AUTH, [status]);

    const handleExpanded = useCallback(() => {
        setIsCompact((prev) => {
            localStorage.setItem('layoutIsCompact', String(!prev));
            return !prev;
        });
    }, []);

    const setLoading = (flag: boolean) => {
        setIsLoading(flag);
    };

    // show popup on websocket connection error
    const { reconnect, RxStompClient } = useWebsocket() || ({} as WebsocketContextType);
    const [wsError, setWsError] = useState<Event | null>(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar() || {};
    const [wsConnectionErrorSnackbarKey, setWsConnectionErrorSnackbarKey] = useState<string>();

    useEffect(() => {
        combineLatest([RxStompClient?.webSocketErrors$, RxStompClient?.connectionState$]).subscribe(([error, state]) => {
            const connectionStateOpen = 1;
            if (state !== connectionStateOpen && error) {
                setWsError(error);
            } else {
                setWsError(null);
            }
        });
    }, [RxStompClient?.webSocketErrors$, RxStompClient?.connectionState$]);

    useEffect(() => {
        if (!wsError) {
            if (wsConnectionErrorSnackbarKey) {
                closeSnackbar(wsConnectionErrorSnackbarKey);
            }
            return;
        }
        const action = (key: string) => {
            setWsConnectionErrorSnackbarKey(key);
            return (
                <>
                    <SnackbarActionButton
                        onClick={() => {
                            void reconnect().catch((e) => {
                                console.info('connect e', e);
                            });
                        }}
                    >
                        {WS_DISCONNECTION_SNACKBAR_BUTTON_TEXT}
                    </SnackbarActionButton>
                </>
            );
        };
        enqueueSnackbar(WS_DISCONNECTION_SNACKBAR_TEXT, { persist: true, variant: 'error', preventDuplicate: true, action });
    }, [wsError, closeSnackbar, enqueueSnackbar, reconnect, wsConnectionErrorSnackbarKey]);

    return isPendingAuth || isNewRegistration ? null : (
        <div className={classNames}>
            <Sidebar className={s.sidebar} setLoading={setLoading} />
            <Header className={s.header} />

            {!isLoading && (
                <WorkspaceProvider>
                    {withLibrary && (
                        <div className={s.menu}>
                            <div className={s.menuHeader}>
                                {!isCompact && (
                                    <Text className={s.title} variant="h3">
                                        Виджеты
                                    </Text>
                                )}
                                <Tooltip title={isCompact ? 'Развернуть' : 'Свернуть'} placement="right">
                                    <IconButton
                                        className={clsx(s.expand, isCompact && s.expandRotate)}
                                        size="small"
                                        aria-label="toogle"
                                        color="primary"
                                        onClick={handleExpanded}
                                    >
                                        <ExpandMoreIcon color="inherit" />
                                    </IconButton>
                                </Tooltip>
                            </div>

                            <Library className={s.library} isCompact={isCompact} />
                        </div>
                    )}
                    <Content
                        type={type}
                        classes={{ root: classNamesContentRoot, widgets: s.widgets, leftPadding: s.leftpadding }}
                        isCompactMenu={isCompact}
                    >
                        {children}
                    </Content>
                </WorkspaceProvider>
            )}
            <SpinnerOverlay isLoading={isLoading} />
        </div>
    );
};

export default Layout;
