import { useCallback, useMemo } from 'react';

import { Tooltip } from '@humans-sdk/core/components';
import * as MuiIcons from '@humans-sdk/core/icons';

import { Card, CardContent, DragItem } from '@components';
import { DropTypes } from '@constants';
import { WidgetCardProps } from '@typings';
import { clsx } from '@utils';

import s from './WidgetCard.module.scss';

const WidgetCard = ({ namespace, name, className, canDrop, onClick, isCompact = false, icon, ...rest }: WidgetCardProps) => {
    const classNameRoot = useMemo(() => clsx(s.root, className), [className]);

    const handleClick = useCallback(() => {
        if (onClick) {
            onClick();
        }
    }, [onClick]);

    // TODO
    const Icon = useMemo(() => MuiIcons?.[icon as 'AccountBalanceWalletIcon'], [icon]);

    const compactInfo = useMemo(
        () => (
            <Tooltip title={name} placement="right">
                {Icon ? (
                    <Icon color="inherit" fontSize="inherit" className={s.icon} />
                ) : (
                    <div className={s.shortName}>{name?.slice(0, 2)}</div>
                )}
            </Tooltip>
        ),
        [Icon, name],
    );

    const card = useMemo(
        () => (
            <Card {...rest} className={classNameRoot} variant="outlined" onClick={handleClick}>
                <CardContent className={clsx(s.content, isCompact && s.compactContent)}>
                    {isCompact ? (
                        compactInfo
                    ) : (
                        <div className={s.name}>
                            {Icon && <Icon color="inherit" fontSize="inherit" className={clsx(s.icon, s.expandIcon)} />}
                            <div className={s.fullName} title={name}>
                                {name}
                            </div>
                        </div>
                    )}
                </CardContent>
            </Card>
        ),
        [rest, classNameRoot, handleClick, isCompact, compactInfo, Icon, name],
    );

    return canDrop ? (
        <DragItem type={DropTypes.simple} namespace={namespace}>
            {card}
        </DragItem>
    ) : (
        card
    );
};

export default WidgetCard;
