import { RouteNames } from '@constants';
import { AppRouteMapped, AppRoutes } from '@typings';

import attachments from './attachments';
import avatar from './avatar';
import demo from './demo';
import home from './home';
import notFound from './notFound';
import scans from './scans';
// import settings from './settings';
import workspace from './workspace';

const routes: AppRoutes = {
    // Home
    [RouteNames.home]: {
        path: '/',
        exact: true,
        auth: true,
        ...home,
    },

    // Demo
    [RouteNames.demo]: {
        path: '/demo',
        exact: true,
        auth: true,
        ...demo,
    },

    // Settings
    // [RouteNames.settings]: {
    //     path: '/settings',
    //     exact: false,
    //     auth: true,
    //     ...settings,
    // },

    // Workspace Page
    [RouteNames.workspace]: {
        path: '/workspaces/:workspaceId',
        exact: true,
        auth: true,
        ...workspace,
    },

    // Avatar Page
    [RouteNames.avatar]: {
        path: '/avatars/:avatarId',
        exact: true,
        auth: true,
        ...avatar,
    },

    [RouteNames.scans]: {
        path: '/scans/:scanId',
        exact: true,
        auth: true,
        ...scans,
    },

    [RouteNames.attachments]: {
        path: '/attachments/:attachmentId',
        exact: true,
        auth: true,
        ...attachments,
    },

    [RouteNames.current]: {
        path: window.location.pathname,
        exact: true,
        auth: true,
    },

    // 404
    [RouteNames.defaultPage]: {
        path: '*',
        ...notFound,
    },
};

/*
    ENUM SORTING `RouteNames` DEFINE PRIORITY IN ROUTE MATCHING
*/
const routesMap: AppRouteMapped[] = Object.values(RouteNames).map((id) => ({ ...routes[id], id }));

export { routes, routesMap };
