import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AvatarSelect, useAuth } from '@humans-sdk/core/kits/auth';
import {
    AuthJoinResultNextStepScreen,
    Avatar,
    Avatars,
    SwitchAvatar_switchAvatar_AdditionalAuthFactorNeededError,
    SwitchAvatar_switchAvatar_SwitchAvatarResult_profile_avatars_available_AvatarWithAuthProvider,
} from '@humans-sdk/core/models';
import { ClickAwayListener } from '@humans-sdk/libs/mui/material';
import { AuthStepsEnum } from '@humans-sdk/typings';

import { useSessionManager } from '@api';
import widgetsCache from '@containers/workspace/provider/widgetsRenderCache';

import s from './AvatarsMenu.module.scss';

interface AvatarsMenuProps {
    avatars: Avatars;
    onClose: () => void;
    avatarPhotoUrl?: string | null;
}

const AvatarsMenu = ({ avatars, onClose, avatarPhotoUrl = avatars?.active.imageUrl }: AvatarsMenuProps) => {
    const [userAvatars, setUserAvatars] = useState<Avatar[]>([]);
    const manager = useSessionManager();
    const { confirmSwitchAvatar } = useAuth();
    const statusMenuAnchorEl = useRef(null);

    const availableAvatars = useMemo<Avatar[]>(
        () =>
            (avatars?.available?.map((item) =>
                (item as Avatar)?.id
                    ? item
                    : (item as SwitchAvatar_switchAvatar_SwitchAvatarResult_profile_avatars_available_AvatarWithAuthProvider)?.avatar,
            ) || []) as Avatar[],
        [avatars],
    );

    const currentAvatars = useMemo<Avatar[]>(
        () => [{ ...avatars?.active, imageUrl: avatarPhotoUrl }, ...(availableAvatars || [])] as Avatar[],
        [availableAvatars, avatarPhotoUrl, avatars?.active],
    );

    useEffect(() => {
        setUserAvatars(currentAvatars);
    }, [currentAvatars]);

    const handleSelectClick = useCallback(
        async (id: string) => {
            if (userAvatars[0].id === id) {
                return;
            }

            onClose();

            try {
                const info = await manager.switchAvatar({ avatarID: id });
                widgetsCache.clear();
                const { authProviders } = (info || []) as SwitchAvatar_switchAvatar_AdditionalAuthFactorNeededError;
                const currentAvatar = userAvatars.filter((avatar) => avatar.id === id)[0];

                manager.updatedNextStep([
                    {
                        screen: AuthJoinResultNextStepScreen.HomeScreen,
                        skippable: false,
                    },
                ]);

                const avatar = {
                    name: currentAvatar.name,
                    id: currentAvatar.id,
                };

                if (authProviders?.length) {
                    confirmSwitchAvatar(AuthStepsEnum.CREDENTIAL_AVATAR, avatar);
                } else {
                    confirmSwitchAvatar(AuthStepsEnum.VERIFY_MASTER_PIN, avatar);
                }
                // eslint-disable-next-line no-empty
            } catch (e) {}
        },
        [confirmSwitchAvatar, manager, onClose, userAvatars],
    );

    return userAvatars.length ? (
        <ClickAwayListener onClickAway={onClose}>
            <div ref={statusMenuAnchorEl} className={s.avatarSelect}>
                <AvatarSelect onClick={handleSelectClick} avatars={userAvatars} />
            </div>
        </ClickAwayListener>
    ) : null;
};

export default AvatarsMenu;
