/* eslint-disable no-bitwise */
import { NameSpaces, SizesBitMap } from '@constants';
import { defaultWorkspaceCells } from '@containers/workspace';
import {
    Dictionary,
    EnterpriseGraphQLSchema,
    LibraryApproved,
    MountGridParams,
    MountWidgetParams,
    UpdateCellsParams,
    UpdateWorkspaceParams,
    WidgetSize,
    WidgetSizeNames,
    WorkspaceCellType,
    WorkspaceStateType,
} from '@typings';
import Registry from '@widgets';

export const firstBitIndex = (x: number): number => {
    return Math.floor(Math.log(x | 0) / Math.log(2)) + 1;
};

export function permutations<T>(list: T[], fixedIndex?: number): T[][] {
    // Empty list has one permutation
    if (list.length === 0) return [[]];

    const result: T[][] = [];
    const len = fixedIndex || list.length;
    const start = fixedIndex ? fixedIndex - 1 : 0;

    for (let i = start; i < len; i += 1) {
        const copy = [...list];

        const head = copy.splice(i, 1);
        const rest = permutations(copy);

        // Add head to each permutation of rest of list
        for (let j = 0; j < rest.length; j += 1) {
            const next = head.concat(rest[j]);
            result.push(next);
        }
    }

    return result;
}

/**
 * Check empty cells as bit mask and return converted to decimal
 * 10000 - all cells are empty
 * @param cells - current cells state
 * @param mask - initial mask
 */
export const getAvailableSpace = (cells: WorkspaceStateType, mask: number = 0): number => {
    const arr = [...cells.map((cell) => Number(!!cell)), '1'].reverse();
    const available = parseInt(arr.join(''), 2);

    // console.info('available', cells, arr, (available >>> 0).toString(2), available | Number(mask));

    return available | Number(mask);
};

export const matchBitmask = (available: number, size: WidgetSize, origin?: number, pinned?: boolean): number => {
    let bitSize = 0;

    // console.groupCollapsed('matchBitmask', available, size, origin);

    SizesBitMap[size].some((mask: number) => {
        const mount = available ^ mask;

        // console.table({
        //     available: {
        //         bit: (available >>> 0).toString(2),
        //         value: available,
        //     },
        //     mask: {
        //         bit: (mask >>> 0).toString(2),
        //         value: mask,
        //     },
        //     mount: {
        //         bit: (mount >>> 0).toString(2),
        //         value: mount,
        //     },
        //     check: {
        //         bit: ((mount | mask) >>> 0).toString(2),
        //         value: mount | mask,
        //     },
        // });

        if ((mount | mask) === mount) {
            // origin can be = 0
            if (typeof origin === 'number') {
                const bit = 2 ** (String(available).length - origin + 1);

                if ((mount & bit) === bit || pinned) {
                    bitSize = mask;
                    return true;
                }

                return false;
            }

            bitSize = mask;
            return true;
        }

        return false;
    });

    // console.groupEnd();

    return bitSize;
};

export const getUniqWidgets = (cells: WorkspaceStateType, namespace?: NameSpaces): NameSpaces[] => {
    const uniq = new Set<NameSpaces>();

    // IMPORTANT: make sure that added widget will be first in array
    if (namespace) {
        uniq.add(namespace);
    }

    cells.forEach((cell) => {
        if (cell?.namespace && !uniq.has(cell?.namespace)) {
            uniq.add(cell?.namespace);
        }
    });

    return [...uniq];
};

export const updateCells = (params: UpdateCellsParams): WorkspaceStateType => {
    const {
        cells,
        widget,
        widget: {
            params: { origin, size },
        },
    } = params;

    let newCells: WorkspaceStateType = [...cells];

    if (size === WidgetSize.TWOxTWO) {
        newCells = [widget, widget, widget, widget];
    } else if (size === WidgetSize.TWOxONE) {
        if ((origin === 0 || origin === 2) && !newCells[0] && !newCells[2]) {
            newCells[0] = widget;
            newCells[2] = widget;
        }
        if ((origin === 1 || origin === 3) && !newCells[1] && !newCells[3]) {
            newCells[1] = widget;
            newCells[3] = widget;
        }
    } else if (size === WidgetSize.ONExONE) {
        for (let i = 0; i < newCells.length; i += 1) {
            if (!newCells[i]) {
                newCells[i] = { ...widget, params: { ...widget.params, origin: i } };

                break;
            }
        }
    }

    // console.log('UPDATE CELLS', newCells, params);

    return newCells;
};

export const emptyAllCells = (): WorkspaceStateType => {
    return defaultWorkspaceCells();
};

export const getCountEmptyCells = (cells: WorkspaceStateType): number => {
    return cells.reduce((count, cell) => count + Number(cell === null), 0);
};

export const setWidgetOrigin = (mask: number): number => {
    return firstBitIndex(mask) - 1;
};

export const mountWidget = ({ namespace, origin, cells, targetSize, library, pinned }: MountWidgetParams): WorkspaceStateType[] => {
    const widget = library[namespace]!;
    // console.log('mountWidget', namespace);

    if (!widget || cells.find((cell) => cell?.namespace === namespace)) {
        return [];
    }

    const { component, settings, id, widgetId } = widget;
    const { sizes = [] } = settings;

    const available = getAvailableSpace(cells);
    const availableSizes = targetSize ? [targetSize] : [...sizes].reverse();

    // console.group('widget', namespace, available, availableSizes, targetSize, cells);

    // find all variants to mount dropped widget (namespace) on workspace
    return availableSizes.reduce<WorkspaceStateType[]>((grid, size) => {
        const mask = matchBitmask(available, size, origin, pinned);

        if (mask) {
            const target = origin !== undefined ? origin : setWidgetOrigin(mask);
            // console.log(`setup ${namespace} origin`, target);

            const modifyCells = updateCells({
                cells,
                widget: { id, widgetId, namespace, component, params: { origin: target, size } },
            });

            grid.push(modifyCells);
        }

        return grid;
    }, []);
};

export const mountGrid = ({ cells, ...params }: MountGridParams): WorkspaceStateType => {
    const { namespace, variants, library } = params;

    const widget = Registry[namespace]!;
    const { settings } = widget;
    const { sizes = [] } = settings;

    const awaitToMount = getUniqWidgets(cells);

    // console.groupCollapsed('grid', variants, awaitToMount);

    if (sizes.length === 1 && sizes[0] === WidgetSize.TWOxTWO) {
        // console.log('MOUNT ONLY ONE WIDGET, ONE SIZE 2x2', namespace);

        return variants[0];
    }

    const grids: WorkspaceStateType[] = [...variants];

    const variantsToMount = permutations(awaitToMount.filter((el) => namespace !== el));

    // console.log('permutations', awaitToMount, variantsToMount);

    variants.forEach((variant) => {
        variantsToMount.forEach((widgets) => {
            let start: WorkspaceStateType[] = [[...variant]];

            for (let i = 0; i < widgets.length; i += 1) {
                const widgetId = widgets[i];

                start = start.flatMap((vcells) => mountWidget({ namespace: widgetId, cells: vcells, library }));

                if (start.length) {
                    grids.push(...start);
                }
            }
        });
    });

    // console.groupEnd();

    // console.log('grids', grids);

    // find best available combination
    const weights: Dictionary<WorkspaceStateType[]> = {};

    grids.forEach((grid) => {
        // widgets to mount
        const widgets = getUniqWidgets(grid);
        const emptyCount = getCountEmptyCells(grid);

        const key = -(100 + cells.length - widgets.length + emptyCount * 0.5);

        if (!weights[key]) {
            weights[key] = [];
        }

        weights[key].push(grid);
    });

    const combinations: WorkspaceStateType[] = Object.keys(weights)
        .sort()
        .flatMap((el) => weights[el].sort((a: WorkspaceStateType, b: WorkspaceStateType) => getCountEmptyCells(a) - getCountEmptyCells(b)));

    // console.info('> Find best combination', { combinations, grids, cells, weights });

    return combinations[0] || cells;
};

export const updateWorkspace = (params: UpdateWorkspaceParams): void => {
    const { namespace, cells, cellIndex, targetSize, onUpdate, library, pinnedStore, enqueueSnackbar } = params;

    // uniq widgets on workspace
    let emptyCells = emptyAllCells();

    if (pinnedStore) {
        emptyCells = emptyCells.map((_cell, idx) => (pinnedStore.includes(cells[idx]?.namespace as string) ? cells[idx] : null));
    }

    // console.warn('WIDGETS NEEDS TO BE MOUNTED ON WORKSPACE:', namespace);

    // console.time('CALC updateWorkspace');

    if (emptyCells.filter(Boolean).length === 4) {
        enqueueSnackbar();

        return;
    }

    const variantsForEmpty = mountWidget({
        namespace,
        cells: emptyCells,
        origin: cellIndex,
        targetSize,
        library,
        pinned: !!pinnedStore?.length,
    });
    const variantsForFilled = mountWidget({ namespace, cells, origin: cellIndex, targetSize, library, pinned: !!pinnedStore?.length });
    const variants = [...variantsForEmpty, ...variantsForFilled];

    if (!variants.length && pinnedStore) {
        // Release pinned if there is no space for new widget
        // updateWorkspace({ ...params, sender: undefined });
        enqueueSnackbar();

        return;
    }
    const grid = mountGrid({ namespace, variants, cells, library });

    onUpdate(grid);

    // console.timeEnd('CALC updateWorkspace');
};

export const widgetSizeAdapter = (size: WidgetSize, origin: number): WidgetSizeNames => {
    if (size === WidgetSize.TWOxTWO) {
        return WidgetSizeNames.TWOxTWO;
    }

    if (size === WidgetSize.TWOxONE && (origin === 0 || origin === 2)) {
        return WidgetSizeNames.TWOxONE_LEFT;
    }

    if (size === WidgetSize.TWOxONE && (origin === 1 || origin === 3)) {
        return WidgetSizeNames.TWOxONE_RIGHT;
    }

    return WidgetSizeNames.ONExONE;
};

export const widgetSizesAdapter = (sizes: WidgetSize[]): WidgetSizeNames[] => {
    const available: WidgetSizeNames[] = [];

    sizes.forEach((size) => {
        available.push(widgetSizeAdapter(size, 0));

        if (size === WidgetSize.TWOxONE) {
            available.push(widgetSizeAdapter(size, 1));
        }
    });

    return available;
};

export const widgetSizeNameAdapter = (size: WidgetSizeNames): WidgetSize => {
    if (size === WidgetSizeNames.TWOxTWO) {
        return WidgetSize.TWOxTWO;
    }

    if (size === WidgetSizeNames.TWOxONE_LEFT || size === WidgetSizeNames.TWOxONE_RIGHT) {
        return WidgetSize.TWOxONE;
    }

    return WidgetSize.ONExONE;
};

export const workspaceAreasAdapter = (cells: WorkspaceStateType): EnterpriseGraphQLSchema.WorkspaceAreaInput[] | null => {
    const widgets = new Set<NameSpaces>();

    return cells.reduce((areas: EnterpriseGraphQLSchema.WorkspaceAreaInput[], cell: WorkspaceCellType) => {
        if (cell) {
            const {
                id,
                widgetId,
                namespace,
                params: { size, origin },
            } = cell;
            const tune: number = Number(origin % 2 === 0);

            if (!widgets.has(namespace)) {
                widgets.add(namespace);

                const common: Omit<EnterpriseGraphQLSchema.WorkspaceAreaInput, 'position'> = {
                    widgetTypeID: id,
                };

                if (widgetId !== id) {
                    common.widgetID = widgetId;
                }

                if (size === WidgetSize.TWOxTWO) {
                    areas.push({
                        position: { rowStart: 1, rowEnd: 2, colStart: 1, colEnd: 2 },
                        ...common,
                    });
                } else if (size === WidgetSize.TWOxONE) {
                    areas.push({
                        position: { rowStart: 1, rowEnd: 2, colStart: 2 - tune, colEnd: 2 - tune },
                        ...common,
                    });
                } else {
                    areas.push({
                        position: { rowStart: origin < 2 ? 1 : 2, rowEnd: origin < 2 ? 1 : 2, colStart: 2 - tune, colEnd: 2 - tune },
                        ...common,
                    });
                }
            }
        }

        return areas;
    }, []);
};

export const areasWorkspaceAdapter = (
    areas: EnterpriseGraphQLSchema.GridArea[] | null | undefined,
    library: LibraryApproved,
): WorkspaceStateType => {
    let output: WorkspaceStateType = defaultWorkspaceCells();

    if (!areas || areas.length === 0) {
        return output;
    }

    for (let i = 0; areas.length > i; i += 1) {
        const area = areas[i];

        const { position, widget } = area;
        const {
            id: widgetId,
            type: { id, namespace: userNamespace },
        } = widget;
        const namespace = userNamespace as NameSpaces;
        const component = library[namespace]?.component!;

        const { colEnd, colStart, rowEnd, rowStart } = position;

        let size: WidgetSize = WidgetSize.ONExONE;
        let origin: number = 0;

        // widget is 2x2
        if (rowStart === 1 && rowEnd === 2 && colStart === 1 && colEnd === 2) {
            size = WidgetSize.TWOxTWO;
            origin = 0;

            const cell = {
                id,
                widgetId,
                namespace,
                component,
                params: { size, origin },
            };

            output = [cell, cell, cell, cell];
            break;
        }
        // widget is 2x1 left
        else if (rowStart === 1 && rowEnd === 2 && colStart === 1 && colEnd === 1) {
            size = WidgetSize.TWOxONE;
            origin = 0;

            const cell = {
                id,
                widgetId,
                namespace,
                component,
                params: { size, origin },
            };

            output[0] = cell;
            output[2] = cell;
        }
        // widget is 2x1 right
        else if (rowStart === 1 && rowEnd === 2 && colStart === 2 && colEnd === 2) {
            size = WidgetSize.TWOxONE;
            origin = 1;

            const cell = {
                id,
                widgetId,
                namespace,
                component,
                params: { size, origin },
            };

            output[1] = cell;
            output[3] = cell;
        } else {
            size = WidgetSize.ONExONE;

            if (rowStart === 1 && rowEnd === 1 && colStart === 1 && colEnd === 1) {
                origin = 0;
            }

            if (rowStart === 1 && rowEnd === 1 && colStart === 2 && colEnd === 2) {
                origin = 1;
            }

            if (rowStart === 2 && rowEnd === 2 && colStart === 1 && colEnd === 1) {
                origin = 2;
            }

            if (rowStart === 2 && rowEnd === 2 && colStart === 2 && colEnd === 2) {
                origin = 3;
            }

            output[origin] = {
                id,
                widgetId,
                namespace,
                component,
                params: { size, origin },
            };
        }
    }

    return output;
};
