import { useLocation } from '@humans-sdk/libs/react-router-dom';

import { routesMap } from '@routes';
import { Dictionary } from '@typings';
import { matchRoute } from '@utils';

const useLocationMatch = (routes = routesMap) => {
    const currentLocation = useLocation<Dictionary>();
    const match = matchRoute(routes, currentLocation);

    return {
        currentLocation,
        ...match,
    };
};

export default useLocationMatch;
