import { ReactElement, useMemo, useState } from 'react';

import { useApolloClient, useSession } from '@api';
import { workspacesAndWidgetsQuery } from '@api/queries';
import { Button, Link } from '@components';
import { DEFAULT_AVATAR_ID, RouteNames } from '@constants';
import { AppsRoundedIcon, CloseRoundedIcon } from '@icons';
import { EnterpriseGraphQLSchema } from '@typings';
import { clsx } from '@utils';

import s from './Header.module.scss';

interface Props {
    className: string;
}

interface State {
    isOpen: boolean;
}

const Header = ({ className }: Props): ReactElement => {
    const { user } = useSession<EnterpriseGraphQLSchema.Profile>();
    const client = useApolloClient();

    const me = client.readQuery<Pick<EnterpriseGraphQLSchema.Query, 'me'>>({
        query: workspacesAndWidgetsQuery,
    })?.me;

    const activeAvatar = useMemo(() => user?.avatars?.active, [user?.avatars?.active]);
    const workspaces = useMemo(() => {
        const all: EnterpriseGraphQLSchema.Workspace[] = [];

        if (me?.workspaces.all.active) {
            all.push(me.workspaces.all.active);
        }

        if (me?.workspaces.all.inactive) {
            all.push(...me.workspaces.all.inactive);
        }

        return all;
    }, [me?.workspaces.all.active, me?.workspaces.all.inactive]);

    const initialState: State = {
        isOpen: false,
    };

    const [state, setState] = useState<State>(initialState);
    const { isOpen } = state;

    const handleToggle = (toggle = !isOpen): void => {
        setState({
            isOpen: toggle,
        });
    };

    const classNamesRoot = useMemo(() => clsx(s.root, className, isOpen && s.open), [className, isOpen]);

    const items = workspaces.map((workspace, index) => {
        const { id, name: nameWorkspace } = workspace;

        // const publicLink = workspace.publicLink || 'default_workspace';
        const firstName: string = user?.personalData?.firstName || 'Johnny';
        const lastName: string = user?.personalData?.lastName || 'Mnemonic';

        // FIXME: very weak check
        const name: string = nameWorkspace === 'Unnamed Workspace' ? `${firstName} ${lastName}` : nameWorkspace;

        const params = { avatarId: activeAvatar?.id || DEFAULT_AVATAR_ID, workspaceId: id };
        const key = `${id}${String(activeAvatar?.id || DEFAULT_AVATAR_ID)}`;

        return (
            <Link key={key} className={clsx(s.link, index === 0 && s.active)} to={RouteNames.workspace} params={params}>
                <Button>{name}</Button>
            </Link>
        );
    });

    return (
        <div className={classNamesRoot}>
            <Button className={s.buttonList} onClick={(): void => handleToggle()}>
                {isOpen ? <CloseRoundedIcon /> : <AppsRoundedIcon />}
            </Button>

            <div className={s.list}>{items}</div>
        </div>
    );
};

export default Header;
