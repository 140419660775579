import { FC, ReactNode, useCallback, useState } from 'react';

import locales from '@humans-sdk/core/utils/locales';

import { Button } from '@components';

const LangSwitch: FC = () => {
    const [lang, setLang] = useState(locales.language);

    const handleChange = useCallback(
        (language: string) => {
            void locales.changeLanguage(language);

            setLang(language);
        },
        [setLang],
    );

    const buttons: ReactNode[] = ['en', 'ru'].map(
        (language) => {
            if (lang === language) {
                return null;
            }

            return (
                <Button key={language} size="small" variant="outlined" onClick={() => handleChange(language)}>
                    {language}
                </Button>
            );
        },
        [lang, handleChange],
    );

    return <>{buttons}</>;
};

export default LangSwitch;
