import { RefAttributes } from 'react';
import { BehaviorSubject, distinctUntilChanged, map, Observable } from 'rxjs';

import { isEqualObjects } from '@humans-sdk/core/utils';
import { WidgetChildProps } from '@humans-sdk/enterprise/typings/components/widget';

import { GenWidgetProps, WorkspaceGridElementType } from '@typings';

import CachedWidgetWrapper from './cachedWidgetWrapper';
import propsConvert from './helpers';

class CachedWidget {
    component: WorkspaceGridElementType;

    private propsSubject: BehaviorSubject<GenWidgetProps>;

    private readonly props$: Observable<WidgetChildProps & RefAttributes<HTMLDivElement>>;

    constructor(props: GenWidgetProps) {
        this.propsSubject = new BehaviorSubject(props);
        this.props$ = this.propsSubject.asObservable().pipe(
            distinctUntilChanged((prev, current) => {
                const prevExceptWidget = (({ Widget: _Widget, ...rest }) => rest)(prev);
                const currentExceptWidget = (({ Widget: _Widget, ...rest }) => rest)(current);
                return isEqualObjects(prevExceptWidget, currentExceptWidget);
            }),
            map(propsConvert),
        );
        this.component = <CachedWidgetWrapper component={props.Widget} props$={this.props$} />;
    }

    passProps(props: GenWidgetProps) {
        this.propsSubject.next(props);
    }
}

export default CachedWidget;
