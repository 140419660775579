import { forwardRef, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { DialogRoutes } from '@humans-sdk/constants/auth';
import { ExitToAppRoundedIcon, HomeRoundedIcon } from '@humans-sdk/core/icons';
import { LogoutButton } from '@humans-sdk/core/kits/auth';
import { UserAvatar } from '@humans-sdk/core/kits/avatar';
import { Avatars } from '@humans-sdk/core/models';
import { useStore } from '@humans-sdk/enterprise/stores';
import { generatePath, useHistory } from '@humans-sdk/libs/react-router-dom';

import { useApolloClient, useQuery, useSession, useSessionManager } from '@api';
import { workspacesAndWidgetsQuery } from '@api/queries';
import { AvatarsMenu, Button, LangSwitch, Link } from '@components';
import { NameSpaces, PINNED, RouteNames } from '@constants';
import { defaultWorkspaceCells, useWorkspaceContext } from '@containers/workspace';
import widgetsCache from '@containers/workspace/provider/widgetsRenderCache';
import { routes } from '@routes';
import { EnterpriseGraphQLSchema, MessageMethod, WorkspaceMessage, WorkspaceMessageAction, WorkspaceRouteProps } from '@typings';
import { clsx, parseQS } from '@utils';

import s from './Sidebar.module.scss';

interface Props {
    className: string;
    setLoading: (flag: boolean) => void;
}

const Sidebar = ({ className, setLoading }: Props): ReactElement => {
    const { user } = useSession<EnterpriseGraphQLSchema.Profile>();
    const { data } = useQuery<Pick<EnterpriseGraphQLSchema.Query, 'me'>>(workspacesAndWidgetsQuery, { fetchPolicy: 'cache-only' });
    const [isAvatarsMenu, setIsAvatarsMenu] = useState(false);
    const isAvatars = useMemo(() => !!user?.avatars.available?.length, [user]);
    const client = useApolloClient();
    const history = useHistory();
    const manager = useSessionManager();
    const { messageStore } = useStore<WorkspaceMessage>({ namespace: NameSpaces.WORKSPACE });
    const { updateGrid } = useWorkspaceContext();

    const workspaceId = useMemo(() => data?.me?.workspaces.all.active?.id ?? 'default_workspace', [data?.me?.workspaces.all.active?.id]);

    const getRedirect = useCallback(
        (id: string | undefined) => {
            if (id) {
                // Redirect to correct URL
                const params: WorkspaceRouteProps = {
                    workspaceId: id,
                };

                const href = generatePath(routes[RouteNames.workspace].path, params);
                history.replace(href);
            }
        },
        [history],
    );

    const handleClick = useCallback(() => {
        setIsAvatarsMenu((prev) => !prev);
        getRedirect(workspaceId);
    }, [getRedirect, workspaceId]);

    const handleClose = useCallback(() => setIsAvatarsMenu(false), []);

    const openClientWidget = (searchInput: string, workspace: NameSpaces, newData: Pick<EnterpriseGraphQLSchema.Query, 'me'>) => {
        const active =
            newData.me?.workspaces.all.active.name.toLocaleLowerCase() === workspace?.toLocaleLowerCase()
                ? [newData.me?.workspaces.all.active]
                : [];
        const inactive =
            newData.me?.workspaces.all.inactive?.filter(({ name }) => name.toLocaleLowerCase() === workspace?.toLocaleLowerCase()) || [];

        const needWorkspace = [...active, ...inactive];

        if (needWorkspace.length) {
            getRedirect(needWorkspace[0]?.id);
            updateGrid(defaultWorkspaceCells());

            setTimeout(() => {
                messageStore.send(
                    [
                        {
                            data: { action: WorkspaceMessageAction.ADD_WIDGET, params: { namespace: NameSpaces.CC_PROFILE } },
                            receiver: NameSpaces.WORKSPACE,
                        },
                    ],
                    MessageMethod.store,
                );

                setTimeout(() => {
                    messageStore.send(
                        [
                            {
                                data: { params: { namespace: NameSpaces.CC_PROFILE, searchInput } },
                                receiver: NameSpaces.CC_PROFILE,
                            },
                        ],
                        MessageMethod.store,
                    );
                }, 4000);
            }, 1000);
        }
    };

    useEffect(() => {
        const getWorkspaces = async () => {
            try {
                setLoading(true);

                const deviceToken = await manager.getDeviceToken();
                const isNewRegistration =
                    history.location.pathname === DialogRoutes.RESET_MASTER_PIN || history.location.pathname === DialogRoutes.REGISTRATION;

                const params = parseQS(history.location.search);
                const { searchInput, workspace } = params as {
                    searchInput?: string;
                    workspace?: NameSpaces;
                };

                if (!deviceToken || isNewRegistration) {
                    if (searchInput) {
                        history.replace(`/${history.location.search}`);
                    }

                    return;
                }

                const { data: newData } = (await client.query({
                    query: workspacesAndWidgetsQuery,
                    fetchPolicy: 'network-only',
                })) as { data: Pick<EnterpriseGraphQLSchema.Query, 'me'> };

                if (searchInput) {
                    localStorage.removeItem(PINNED);

                    openClientWidget(searchInput, workspace as NameSpaces, newData);
                } else {
                    const newWorkspaceId = newData.me?.workspaces.all.active.id;
                    getRedirect(newWorkspaceId);
                }
            } finally {
                setLoading(false);
            }
        };

        void getWorkspaces();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleLogout = useCallback(() => widgetsCache.clear(), []);

    return (
        <div className={clsx(s.sidebar, className)}>
            <div className={s.header}>
                {user && <UserAvatar size={6} profile={user} className={s.avatar} onClick={isAvatars ? handleClick : undefined} />}
                {isAvatarsMenu && (
                    <AvatarsMenu avatars={(user?.avatars || []) as Avatars} onClose={handleClose} avatarPhotoUrl={user?.avatarPhotoUrl} />
                )}
                <Button
                    aria-label="Workspaces"
                    className={s.link}
                    component={forwardRef((props, r) => (
                        <Link
                            ref={r}
                            activeClassName={s.active}
                            exact={false}
                            to={RouteNames.workspace}
                            params={{ workspaceId }}
                            {...props}
                        />
                    ))}
                >
                    <HomeRoundedIcon />
                </Button>

                <LogoutButton variant="text" className={s.link} onLogout={handleLogout} redirectUrl="/">
                    <ExitToAppRoundedIcon />
                </LogoutButton>
            </div>
            <div className={s.footer}>
                <LangSwitch />
            </div>
        </div>
    );
};

export default Sidebar;
