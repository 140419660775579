/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { DropPlace, EmptyWorkspace } from '@components';
import { DropTypes } from '@constants';
import { useWorkspaceContext } from '@containers/workspace';
import { ContentProps, ContentType, WorkspaceStateType } from '@typings';
import { clsx } from '@utils';

import s from './Content.module.scss';

const Content = ({ classes, children, type = ContentType.components, isCompactMenu }: ContentProps): ReactElement => {
    const classNamesRoot = useMemo(() => clsx(s.root, classes?.root), [classes]);
    const classNamesWidgets = useMemo(() => clsx(s.widgets, classes?.widgets), [classes]);
    const leftPadding = parseInt(classes?.leftPadding, 10);
    const localStorageName = 'gridColumnsTemplate';
    const localStorageGridColumnsTemplate = localStorage.getItem(localStorageName);
    const leftColumnWidthRef = useRef<HTMLDivElement | null>(null);
    const widgetContainerRef = useRef<HTMLDivElement | null>(null);
    const [leftColumnWidth, setLeftColumnWidth] = useState<number>(0);
    const [mouseIsUp, setMouseIsUp] = useState<boolean>(true);
    const [gridTemplateColumns, setGridTemplateColumns] = useState<string>(localStorageGridColumnsTemplate || '50% 50%');
    const { grid, handleDrop, gridTemplateAreas, cells } = useWorkspaceContext();
    const minColumnWidth = 360;
    const smWindowMediaWidth = 768;
    const [x, setX] = useState<number>(0);

    const updateLeftColumnWidth = useCallback(() => {
        setLeftColumnWidth(leftColumnWidthRef.current?.offsetWidth || 0);
    }, []);

    useEffect(() => {
        updateLeftColumnWidth();
        window.addEventListener('resize', updateLeftColumnWidth);

        return () => {
            window.removeEventListener('resize', updateLeftColumnWidth);
        };
    }, [updateLeftColumnWidth]);

    useEffect(() => {
        updateLeftColumnWidth();
    }, [isCompactMenu, updateLeftColumnWidth]);

    useEffect(() => {
        if (mouseIsUp) {
            localStorage.setItem(localStorageName, gridTemplateColumns);
        }
    }, [mouseIsUp, gridTemplateColumns]);

    const isExistSelectGrid = useMemo(() => grid.some((item) => item), [grid]);
    const mouseMoveHandler = useCallback(
        (e: MouseEvent) => {
            let mouseMovedDistance: number = x;

            setX((prev) => {
                mouseMovedDistance = e.clientX - prev;
                return prev;
            });
            const widgetContainerWidth = widgetContainerRef.current?.offsetWidth || 0;
            const newLeftColumnWidth = leftColumnWidth + mouseMovedDistance;
            const newRightColumnWidth = widgetContainerWidth - newLeftColumnWidth;
            if (newLeftColumnWidth > minColumnWidth && newRightColumnWidth > minColumnWidth) {
                setLeftColumnWidth(newLeftColumnWidth);
                const leftColumnWidthPercentage = Math.round((newLeftColumnWidth / widgetContainerWidth) * 10000) / 100;
                const rightColumnWidthPercentage = Math.round((100 - leftColumnWidthPercentage) * 100) / 100;
                const gridTemplateColumnsString = `${leftColumnWidthPercentage}% ${rightColumnWidthPercentage}%`;
                setGridTemplateColumns(gridTemplateColumnsString);
            }
        },
        [leftColumnWidth, x],
    );

    const mouseUpHandler = useCallback(() => {
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
        setMouseIsUp(true);
    }, [mouseMoveHandler]);

    const mouseDownHandler = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            setX(e.clientX);
            e.preventDefault();
            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
            setMouseIsUp(false);
        },
        [mouseMoveHandler, mouseUpHandler],
    );

    const content: ReactNode = useMemo(() => {
        if (type === ContentType.components) {
            return children;
        }

        let isWidgetMount = false;

        const isFullScreenWidget = (arr: WorkspaceStateType) => arr?.every((v: any) => v === arr[0]);

        const slots = grid.map((gridItem, i: number) => {
            const key = `cell-${String(i)}`;
            if (gridItem && !isWidgetMount) {
                isWidgetMount = true;
            }

            return (
                <DropPlace
                    index={i + 1}
                    ref={leftColumnWidthRef}
                    key={key}
                    accept={[DropTypes.simple, DropTypes.complex]}
                    onDrop={(item) => {
                        handleDrop(item, i);
                    }}
                    className={s.dropPlace}
                    isExistSelectGrid={isExistSelectGrid}
                />
            );
        });

        return (
            <>
                {isWidgetMount && !isFullScreenWidget(cells) && window.innerWidth > smWindowMediaWidth && (
                    <div className={s.resizeButton} style={{ left: `${leftColumnWidth + leftPadding}px` }} onMouseDown={mouseDownHandler}>
                        <div className={s.resizeButtonCenterLine} />
                    </div>
                )}
                {slots && (
                    <div ref={widgetContainerRef} className={s.slots} style={{ gridTemplateColumns }}>
                        {slots}
                    </div>
                )}
                {isWidgetMount ? (
                    <div className={classNamesWidgets} style={{ gridTemplateAreas, gridTemplateColumns }}>
                        {grid}
                    </div>
                ) : (
                    <EmptyWorkspace />
                )}
            </>
        );
    }, [
        type,
        grid,
        classNamesWidgets,
        gridTemplateAreas,
        children,
        isExistSelectGrid,
        handleDrop,
        leftColumnWidth,
        gridTemplateColumns,
        cells,
        mouseDownHandler,
        leftPadding,
    ]);

    return <div className={classNamesRoot}>{content}</div>;
};

export default Content;
