/*
    WARNING: ENUM SORTING DEFINE PRIORITY IN ROUTE MATCHING
*/
export enum RouteNames {
    current = 'current',
    home = 'home',
    demo = 'demo',
    // settings = 'settings',
    avatar = 'avatar',
    scans = 'scans',
    attachments = 'attachments',
    workspace = 'workspace',
    defaultPage = 'defaultPage',
}

export default RouteNames;
