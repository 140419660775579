import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { Location } from 'history';

import { DialogRoutes } from '@humans-sdk/constants/auth';
import { useSessionManager } from '@humans-sdk/core/api/session';
import { Route, Switch, useHistory } from '@humans-sdk/libs/react-router-dom';
import { AuthStatuses } from '@humans-sdk/typings';

import { useApolloClient, useAuth, useSession } from '@api';
import { LoaderNavigate } from '@components';
import { RoutingStatus } from '@constants';
import { AppRoute, AppRouteMapped, Dictionary, DynamicModule, User } from '@typings';
import { defaultRoute, matchRoute, parseQS, setUserContext } from '@utils';
import { useLocationMatch } from '@utils/hooks';

interface Props {
    routesMap: AppRouteMapped[];
}
interface State {
    route: AppRoute;
    location: Location;
    user: User | null;
}

const Prefetcher = (props: Props): ReactElement => {
    const { routesMap } = props;
    const { status, sessionStarted, authenticateMe } = useAuth();
    const { user: currentUser } = useSession();
    const sessionManager = useSessionManager();
    const client = useApolloClient();

    const isEntry = useRef(true);
    const history = useHistory<Dictionary>();
    const { currentLocation, route: currentRoute } = useLocationMatch();

    const [inProgress, setProgress] = useState(false);

    const initialState: State = {
        route: currentRoute,
        location: currentLocation,
        user: currentUser || null,
    };

    const [state, setState] = useState(initialState);
    const { route, location, user } = state;

    const fetchRoute = useCallback(
        async (nextLocation: Location): Promise<void> => {
            const { route: nextRoute, match } = matchRoute(routesMap, nextLocation);

            if (nextLocation.pathname === DialogRoutes.RESET_MASTER_PIN || nextLocation.pathname === DialogRoutes.REGISTRATION) {
                isEntry.current = true;

                return Promise.resolve();
            }

            if (nextRoute.auth && status !== AuthStatuses.AUTHENTICATED) {
                if ((status !== AuthStatuses.PENDING_AUTH && sessionStarted) || status === AuthStatuses.UNAUTHENTICATED) {
                    authenticateMe(nextRoute.path, defaultRoute.path);
                }

                return Promise.resolve();
            }

            setProgress(true);
            isEntry.current = false;

            const promises: [Promise<DynamicModule>, Promise<unknown> | void] = [
                nextRoute.module ? nextRoute.module() : Promise.resolve({ default: () => null }),
                undefined,
            ];

            if (nextRoute.fetch) {
                const params = parseQS(location.search);

                const requests = nextRoute.fetch({
                    match,
                    history,
                    client,
                    user,
                    sessionManager,
                    location,
                    params,
                });

                promises.push(...requests);
            }

            const errorCatch = (e: Error | RoutingStatus) => {
                if (e !== RoutingStatus.redirect) {
                    setProgress(false);

                    if (e instanceof Error) {
                        console.info(`Error while routing to ${nextRoute.path}`);
                        throw e;
                    }
                }
            };

            return Promise.all(promises)
                .then(
                    (resolve) => {
                        const [module] = resolve;
                        nextRoute.component = module.default;

                        setState({
                            route: nextRoute,
                            location: nextLocation,
                            user: currentUser || null,
                        });

                        setProgress(false);
                    },
                    (e: Error) => errorCatch(e),
                )
                .catch(errorCatch);
        },
        [authenticateMe, client, currentUser, history, location, routesMap, sessionManager, sessionStarted, status, user],
    );

    useEffect(() => {
        if (location.key !== currentLocation.key || isEntry.current) {
            void fetchRoute(currentLocation);
        }
    }, [currentLocation, sessionStarted, fetchRoute, location]);

    useEffect(() => {
        if (currentUser) {
            setUserContext(currentUser);
        }
    }, [currentUser]);

    return (
        <>
            <LoaderNavigate inProgress={inProgress} />
            <Switch location={location}>
                <Route path={route.path} exact={route.exact} component={route.component} />
            </Switch>
        </>
    );
};

export default Prefetcher;
