import { ReactElement } from 'react';

import { WebsocketProvider } from '@humans-sdk/core/kits';
import locales from '@humans-sdk/core/utils/locales';
import { ApolloProvider } from '@humans-sdk/libs/apollo/client';
import { BrowserRouter } from '@humans-sdk/libs/react-router-dom';

import { AuthProvider, manager } from '@api';
import { Prefetcher } from '@containers';
import { routesMap } from '@routes';

export default function Router(): ReactElement {
    const basename = window?.$$environment?.BASE_ROOT_URI || '';
    return (
        <BrowserRouter basename={basename}>
            <AuthProvider manager={manager} i18n={locales} config={{ noCloseButton: true, displayTermsControl: false }}>
                <ApolloProvider client={manager.apolloGqlClient}>
                    <WebsocketProvider>
                        <Prefetcher routesMap={routesMap} />
                    </WebsocketProvider>
                </ApolloProvider>
            </AuthProvider>
        </BrowserRouter>
    );
}
