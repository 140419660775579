import { forwardRef } from 'react';

import { stringToHslColor } from '@humans-sdk/core/utils/helpers';
import { WidgetChildProps } from '@humans-sdk/enterprise/typings';

import { Text, WidgetWrapper } from '@components';
import { NameSpaces } from '@constants';
import { WidgetComponent } from '@typings';

const namespace = NameSpaces.CC_DOCUMENTS_TELCO;

const Widget10 = forwardRef<HTMLDivElement, WidgetChildProps>(({ children, ...props }, ref) => (
    <WidgetWrapper title={`Widget ${namespace}`} namespace={namespace} {...props} refetcheble={false} ref={ref}>
        <div
            style={{
                padding: '10px',
                height: '100%',
                backgroundColor: stringToHslColor(
                    `${namespace}${namespace}`,
                    240 - Number(namespace.replace(/[A-z]+/, '')) * 5,
                    10 + Number(namespace.replace(/[A-z]+/, '')) * 5,
                ).replace(')', ',0.4)'),
            }}
        >
            <Text>{`Content ${namespace}`}</Text>
            {children}
        </div>
    </WidgetWrapper>
));

export default Widget10 as WidgetComponent;
