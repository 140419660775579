import { RefAttributes } from 'react';

import { WidgetChildProps } from '@humans-sdk/enterprise/typings/components/widget';

import { GenWidgetProps } from '@typings';
import { widgetSizeAdapter } from '@utils';

function propsConvert(newProps: Omit<GenWidgetProps, 'Widget'>): WidgetChildProps & RefAttributes<HTMLDivElement> {
    const { size, origin, namespace, availableSizes, handleSizeChange, handleRemoveWidget, handlePinned, pinned = false } = newProps;
    return {
        key: namespace,
        title: namespace,
        foldable: false,
        folded: false,
        availableSizes,
        currentSize: widgetSizeAdapter(size, origin),
        changeSize: (targetSize) => handleSizeChange({ targetSize, size, origin, namespace }),
        removeWidget: () => handleRemoveWidget?.(namespace),
        handlePinned: () => handlePinned?.(namespace),
        pinnable: true,
        pinned,
    };
}

export default propsConvert;
