import { forwardRef, MouseEvent, ReactElement, ReactNode, Ref } from 'react';
import { Location } from 'history';

import { generatePath, match, NavLink, useRouteMatch } from '@humans-sdk/libs/react-router-dom';

import { RouteNames } from '@constants';
import { routes } from '@routes';
import { RouteParamsProps } from '@typings';
import { clsx } from '@utils';

type Props = Partial<Pick<RouteParamsProps, 'params'>> & {
    activeClassName?: string;
    children?: ReactNode;
    className?: string;
    onClick?: (event: MouseEvent) => unknown;
    byRouter?: boolean;
    exact?: boolean;
    to: keyof typeof RouteNames;
    isActive?<Params extends { [K in keyof Params]?: string }>(match: match<Params> | null, location: Location): boolean;
};

/**
 * Renders link element, but for page navigation usage.
 * i.e. processes passed "to" prop, and creates page url of it
 */

const Link = (props: Props, ref: Ref<HTMLAnchorElement>): ReactElement => {
    const { activeClassName, children, className, onClick, to, byRouter = true, exact = true, params = {}, ...rest } = props;
    const routeMatch = useRouteMatch(to);

    if (!byRouter) {
        return (
            <a className={clsx(className, routeMatch && activeClassName)} href={to} onClick={onClick}>
                {children}
            </a>
        );
    }

    const { path } = routes[to];
    const href = generatePath(path, params);

    return (
        <NavLink ref={ref} activeClassName={activeClassName} className={className} onClick={onClick} to={href} exact={exact} {...rest}>
            {children}
        </NavLink>
    );
};

export default forwardRef(Link);
