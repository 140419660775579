import locales, { useTranslation } from '@humans-sdk/core/utils/locales';

import { Button, Text } from '@components';
import { URL_HELPDESK_ACCESS } from '@constants';
import { NoAccessIcon } from '@icons';

import en from './locales/en-US.json';
import ru from './locales/ru-RU.json';

import s from './NoAccess.module.scss';

const localeName = 'component.NoAccess';
locales.addResourceBundle('en', localeName, en);
locales.addResourceBundle('ru', localeName, ru);

const NoAccess = () => {
    const { t } = useTranslation(localeName);

    return (
        <div className={s.root}>
            <NoAccessIcon classes={{ root: s.icon }} />
            <Text variant="h1" className={s.caption}>
                {t('reason')}
            </Text>
            <Text variant="body1" className={s.text}>
                {t('comment')}
            </Text>
            <Button size="large" variant="contained" color="primary" target="_blank" href={URL_HELPDESK_ACCESS}>
                {t('buttonLabel')}
            </Button>
        </div>
    );
};

export default NoAccess;
