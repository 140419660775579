enum SettingsNames {
    details = 'details',
    contacts = 'contacts',
    security = 'security',
}

const defaultSettingsName = SettingsNames.details;
export { defaultSettingsName, SettingsNames };

export default SettingsNames;
