import { DynamicImport } from '@typings';

const AvatarPage: DynamicImport = () =>
    import(
        /* webpackPrefetch: true */
        /* webpackChunkName: 'AvatarPage' */
        './avatarPage'
    );

export default {
    module: AvatarPage,
    // fetch: ({ api, connectStore, match }: AppRouteFetchProps<AvatarRouteParams>): AppRouteFetchReturn => {
    //     return [api.queryMe({}, PROFILE).then(({ me }) => avatarResolver({ connectStore, match, me }))];
    // },
};
