import { workspacesAndWidgetsQuery } from '@api/queries';
import { AppRouteFetchProps, AppRouteFetchReturn, DefaultRouteProps, DynamicImport, EnterpriseGraphQLSchema } from '@typings';

const HomePage: DynamicImport = () =>
    import(
        /* webpackPrefetch: true */
        /* webpackChunkName: 'HomePage' */
        './homePage'
    );

export default {
    module: HomePage,
    fetch: ({ client }: AppRouteFetchProps<DefaultRouteProps>): AppRouteFetchReturn => [
        client.query<EnterpriseGraphQLSchema.Query>({
            query: workspacesAndWidgetsQuery,
            fetchPolicy: 'network-only',
        }),
    ],
};
