export * from './content';
export * from './dnd';
export * from './library';
export * from './routes';
export * from './workspace';
export * from '@humans-sdk/enterprise/typings/components/widget';
export * from '@humans-sdk/enterprise/typings/registry';
export * from '@humans-sdk/enterprise/typings/stores';
export * from '@humans-sdk/typings';

declare global {
    interface Array<T> {
        // T[] | [T] enforces a tuple type. {[K in keyof this]: U} keeps a mapped tuple type.
        map<U>(callbackfn: (value: T, index: number, tuple: T[] | [T]) => U, thisArg?: any): { [K in keyof this]: U };
    }
}
